import axios from 'axios';
import { navigate } from 'gatsby';
import qs from 'query-string';
import React, { useEffect } from 'react';
import { wrapComponent } from 'react-snackbar-alert';
import {
	DeliveryDetails,
	DeliveryForm,
	EnableMessenger,
	FlexdelForm,
	FlexdelModified,
	ParcelDetailsHeader,
	PickupPoint,
	RecipientRating,
	Signature,
} from './components';
import CourierContact from './components/CourierContact';

const ParcelDetailsPage = ({ parcel, createSnackbar, messengerLink }) => {
	const {
		_id,
		drop_off_stop,
		drop_off_address,
		cashOnDelivery,
		creditCardEnabled,
		sender_company,
		recipient_name,
		recipient_company,
		recipient_phone,
		recipient_extra_notes,
		recipient_extra_notes_old,
		recipient_flexdel_date,
		recipient_flexdel_address,
		terminalAvailable,
		detailsAccessToken: { token },
		rating,
		_courier,
		delivery_date,
		closed,
	} = parcel;

	const showCourierContact = process.env.GATSBY_SHOW_COURIER_PHONE_NUMBER;

	const editParcel = (payload, setSubmitting) => {
		axios
			.get(
				`/.netlify/functions/edit-parcel?${qs.stringify({
					...payload,
					token,
					_id,
				})}`
			)
			.then(() => {
				if (payload.messageType === 'flexdel') {
					window.scrollTo(0, 0);
					navigate('/success', { state: { type: 'flexDel' } });
				} else {
					createSnackbar({ message: 'Sikeres módosítás!', theme: 'success' });
				}
			})
			.catch(() =>
				createSnackbar({
					message: 'Hiba történt',
					theme: 'error',
				})
			)
			.finally(() => setSubmitting(false));
	};

	const sendPickupPointMessage = () => {
		axios
			.get(
				`/.netlify/functions/pickup-point?${qs.stringify({
					token,
					_id,
				})}`
			)
			.then(() => {
				window.scrollTo(0, 0);
				navigate('/success', { state: { type: 'pickupPoint' } });
			})
			.catch((err) =>
				createSnackbar({
					message: err.response.data || 'Hiba történt',
					theme: 'error',
				})
			);
	};

	const isDelivered = parcel.status.code === 10;
	const isRateable = parcel.status.code === 10 || parcel.status.code === 7;
	const isExoParcel = parcel.deliveryType === 'exo';
	const isDpdParcel = parcel.deliveryType === 'dpd';

	// POSTA pickup point disabled START
	// const isPostaParcel = parcel.deliveryType === 'posta';
	// const hasValidExternalCollectiveIdForPostaPickupPointPilot =
	// 	parcel.externalCollectiveId === '6890';
	// const canRequestPickupPoint =
	// 	isExoParcel ||
	// 	(isPostaParcel && hasValidExternalCollectiveIdForPostaPickupPointPilot);

	const canRequestPickupPoint = isExoParcel;
	// POSTA pickup point disabled END

	const canPayWithCreditCard = terminalAvailable && creditCardEnabled;

	const FlexdelComp = () => {
		const isFlexdelModified = recipient_flexdel_date;

		return isFlexdelModified ? (
			<FlexdelModified
				recipient_flexdel_date={recipient_flexdel_date}
				recipient_flexdel_address={recipient_flexdel_address}
			/>
		) : (
			<FlexdelForm
				recipient_flexdel_address={recipient_flexdel_address}
				editParcel={editParcel}
			/>
		);
	};

	useEffect(() => {
		if (closed === true && !isDelivered) {
			window.scrollTo(0, 0);
			navigate('/error', { state: { type: 'pickupPoint' } });
		}
	}, [closed, isDelivered]);

	return (
		<>
			<ParcelDetailsHeader
				isDelivered={isDelivered}
				isRateable={isRateable}
				isDpdParcel={isDpdParcel}
			/>
			<section className="pt-0">
				<div className="container">
					{isRateable ? (
						<>
							{showCourierContact && !isDelivered && (
								<CourierContact
									courierPhoneNumber={_courier.local.phone_number}
									deliveryDate={delivery_date}
								/>
							)}
							<RecipientRating _id={_id} token={token} rating={rating} />
						</>
					) : (
						<>
							<DeliveryDetails
								canPayWithCreditCard={canPayWithCreditCard}
								drop_off_stop={drop_off_stop}
								drop_off_address={drop_off_address}
								cashOnDelivery={cashOnDelivery}
								sender_company={sender_company}
								recipient_name={recipient_name}
								recipient_company={recipient_company}
								recipient_phone={recipient_phone}
								deliveryType={parcel.deliveryType}
							/>
							<Signature _id={_id} token={token} />
							<EnableMessenger messengerLink={messengerLink} />
							{canRequestPickupPoint && (
								<PickupPoint sendPickupPointMessage={sendPickupPointMessage} />
							)}
							<DeliveryForm
								recipient_extra_notes_old={recipient_extra_notes_old}
								recipient_extra_notes={recipient_extra_notes}
								recipient_phone={recipient_phone}
								editParcel={editParcel}
							/>
							{isExoParcel && <FlexdelComp />}
						</>
					)}
				</div>
			</section>
		</>
	);
};

export default wrapComponent(ParcelDetailsPage);
