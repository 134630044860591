import React, { useState } from 'react';
import qs from 'query-string';
import axios from 'axios';
import moment from 'moment';
import { wrapComponent } from 'react-snackbar-alert';

const SpecialDelivery = ({ createSnackbar, parcel }) => {
	const {
		_id,
		detailsAccessToken: { token },
		special_delivery: { answer, type: specialDeliveryType },
		status: { code },
		availability,
	} = parcel;

	const submittedDefaultValue = answer !== 'NOT_KNOWN';
	const [submitted, setSubmitted] = useState(submittedDefaultValue);

	const specialDeliveryTexts = {
		WEEKEND_DELIVERY: { paragraph: 'Szombati', button: 'a szombati' },
		LATE_DELIVERY: { paragraph: 'Esti', button: 'az esti' },
	};
	const specialDeliveryName =
		specialDeliveryTexts[specialDeliveryType].paragraph;
	const specialDeliveryBtnText =
		specialDeliveryTexts[specialDeliveryType].button;

	const linkExpiryDate = moment(availability).hour(17).minute(0);
	const isLinkExpired = code !== 11;

	const setAnswer = (questionAnswer) => {
		axios
			.get(
				`/.netlify/functions/special-delivery-answer?${qs.stringify({
					token,
					_id,
					answer: questionAnswer,
				})}`
			)
			.then(() => setSubmitted(true))
			.catch((err) =>
				createSnackbar({
					message: err.response.data || 'Hiba történt',
					theme: 'error',
				})
			);
	};

	const CardContent = () => {
		if (isLinkExpired) {
			return (
				<p className="pb-2">
					Ez a link lejárt. A speciális kézbesítésre jelentkezés határideje
					lejárt.
				</p>
			);
		}

		if (!submitted) {
			return (
				<div className="d-flex justify-content-around">
					<button
						type="submit"
						onClick={() => setAnswer('YES')}
						className="btn btn-lg btn-primary my-3"
					>
						Kérem {specialDeliveryBtnText} kézbesítést
					</button>

					<button
						type="submit"
						onClick={() => setAnswer('NO')}
						className="btn btn-lg btn-secondary my-3"
					>
						Nem kérem {specialDeliveryBtnText} kézbesítést
					</button>
				</div>
			);
		}

		if (submitted) {
			return (
				<>
					<p className="pb-2">Köszönjük a válaszodat!</p>
					<p>
						Mi a ViddL-nél azon dolgozunk, hogy a csomagszállítás minél gyorsabb
						és jobb legyen, ezért nem félünk az újításoktól, ha a csomag
						címzettek elégedettségéről van szó. A kezbesítés napján további
						pontos információkkal jövünk a kiszállítás időpontjáról.
					</p>
				</>
			);
		}

		return null;
	};

	return (
		<section className="pt-0">
			<div className="container">
				<div className="row bg-primary text-center pb-0 pt-5 pt-md-2 pb-5">
					<div className="col-12 col-md-8 offset-md-2 text-md-center pb-5">
						<>
							<h2>{specialDeliveryName} kézbesítés igénylése</h2>
							<h4 className="mb-5 mb-md-4 font-weight-normal pb-lg-5 pt-3">
								<span>Lehetőséged van</span>
								<span className="font-weight-bold">
									{' '}
									{specialDeliveryName} kézbesítést{' '}
								</span>
								<span>
									kérni tőlünk, amennyiben ez megfelel számodra. Ebben az
									esetben kérlek jelezd nekünk a mai napon
								</span>
								<span className="font-weight-bold">
									{` ${linkExpiryDate.format('YYYY.MM.DD, hh:mm')} `}
								</span>
								<span>-ig az alábbi gombokra való kattintással.</span>
							</h4>
						</>
					</div>
				</div>
				<div className="card">
					<div className="card-body">
						<CardContent />
					</div>
				</div>
			</div>
		</section>
	);
};

export default wrapComponent(SpecialDelivery);
