import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import axios from 'axios';
import { SnackbarProvider } from 'react-snackbar-alert';
import Layout from '../components/layout/Layout';
import LoadingScreen from '../components/layout/LoadingScreen';
import ParcelDetailsPage from '../components/ParcelDetails/ParcelDetailsPage';
import SpecialDelivery from '../components/ParcelDetails/components/SpecialDelivery';

const NotFoundPage = ({ data, location: { pathname } }) => {
	const [result, setResult] = useState(null);
	const [messenger, setMessengerLink] = useState('');
	const [_, shortId, subRouteName] = pathname.split('/');

	useEffect(() => {
		const fetchData = () =>
			axios.get(`/.netlify/functions/retrieve?shortId=${shortId}`);

		if (result === null) {
			fetchData()
				.then(({ data: { parcel, messengerLink } }) => {
					setResult(parcel);
					setMessengerLink(messengerLink);
				})
				.catch(() => navigate('/error'));
		}
	}, [result, shortId]);

	const RouteHandler = () => {
		// Handle sub routes
		if (
			subRouteName === 'ld' &&
			result?.special_delivery?.type === 'LATE_DELIVERY'
		) {
			return <SpecialDelivery parcel={result} />;
		}

		if (
			subRouteName === 'wd' &&
			result?.special_delivery?.type === 'WEEKEND_DELIVERY'
		) {
			return <SpecialDelivery parcel={result} />;
		}

		// Handle main route
		return <ParcelDetailsPage parcel={result} messengerLink={messenger} />;
	};

	return (
		<Layout footerImg={data.logo_footer} footerEitImg={data.logo_eit_footer}>
			<SnackbarProvider position="bottom">
				{result === null ? (
					<LoadingScreen loading={data.loading} />
				) : (
					<RouteHandler />
				)}
			</SnackbarProvider>
		</Layout>
	);
};

export const query = graphql`
	{
		logo_footer: file(relativePath: { eq: "logo_footer.png" }) {
			childImageSharp {
				fixed(width: 92, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		logo_eit_footer: file(relativePath: { eq: "eit.png" }) {
			childImageSharp {
				fixed(width: 200, quality: 100) {
					...GatsbyImageSharpFixed_withWebp_tracedSVG
				}
			}
		}
		loading: file(relativePath: { eq: "loading.png" }) {
			childImageSharp {
				fluid(maxWidth: 350, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;

export default NotFoundPage;
